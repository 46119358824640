<template>
  <div>
    <section class="modal-card-body">
      <article class="panel" :class="{'is-info': !creating, 'is-success': creating}">
        <div class="panel-heading">
            <span v-if="creating">Create New</span>
            <span v-else-if="viewer">{{ selectedTemplate.name }}</span>
            <span v-else>Select Template</span>
            <button
              v-if="!creating && !viewer"
              class="button is-pulled-right is-small is-rounded"
              :class="{'tooltip': creating, 'is-tooltip-top': creating}"
              data-tooltip="Add Template"
              @click="onCreateNew"
            >
              <span class="icon">
                <icon :icon="['fas', 'plus-circle']" />
              </span>
            </button>
        </div>
        <div class="main-container">
          <!-- Creating View -->
          <template v-if="creating">
            <div class="columns m-0">
              <div class="column is-full">
                <p class="m-0">Template Name</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-full">
                <input
                  v-model="templateName"
                  ref="templateName"
                  class="input is-primary"
                  type="text"
                  placeholder="Template Name"
                >
              </div>
            </div>
            <div v-if="templateName" class="fields-container">
              <div class="columns my-2"  v-for="(field, idx) in fields" :key="idx + field.fieldId">
                <div class="column">
                  <div class="field" style="padding-top: .5rem;">
                    <input
                      v-model="field.checked"
                      :id="idx + field.fieldId"
                      :name="idx + field.fieldId"
                      class="is-checkradio"
                      type="checkbox"
                      @change="updateInput(idx, field)"
                    >
                    <label :for="idx + field.fieldId">{{ field.name }}</label>
                  </div>
                </div>
                <div class="column">
                  <input
                    v-model="field.alias"
                    :ref="idx + field.fieldId"
                    :disabled="!field.checked"
                    class="input is-primary"
                    type="text"
                    placeholder="Alias"
                  >
                </div>
              </div>
              <div v-for="linkedForm in linkedForms" :key="linkedForm._id">
                <h3>{{ linkedForm.title }}</h3>
                <div class="columns my-2"  v-for="(field, idx) in linkedForm.fields" :key="idx + field.fieldId">
                  <div class="column">
                    <div class="field" style="padding-top: .5rem;">
                      <input
                        v-model="field.checked"
                        :id="idx + field.fieldId"
                        :name="idx + field.fieldId"
                        class="is-checkradio"
                        type="checkbox"
                        @change="updateInput(idx, field)"
                      >
                      <label :for="idx + field.fieldId">{{ field.name }}</label>
                    </div>
                  </div>
                  <div class="column">
                    <input
                      v-model="field.alias"
                      :ref="idx + field.fieldId"
                      :disabled="!field.checked"
                      class="input is-primary"
                      type="text"
                      placeholder="Alias"
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- Selected View -->
          <template v-else-if="viewer">
            <div
              v-for="(field, idx) in selectedTemplate.fields" :key="idx + field.fieldId"
              class="panel-block is-justify-content-flex-end"
            >
              <p style="margin: 0 auto 0 0;">{{ field.alias || field.name }}</p>
            </div>
            <div v-for="(linkedForm, idx) in selectedTemplate.linkedForms" :key="idx + linkedForm._id">
              <div
                v-for="(field, idx) in linkedForm.fields" :key="idx + field.fieldId"
                class="panel-block is-justify-content-flex-end"
              >
                <p style="margin: 0 auto 0 0;">{{ field.alias || field.name }}</p>
              </div>
            </div>
          </template>
          <!-- Template List View -->
          <template v-else>
            <div v-if="!templates.length" class="has-text-centered my-3">
              No templates were found.
            </div>
            <div
              v-for="(template, idx) in templates" :key="idx + template._id"
              class="panel-block is-justify-content-flex-end"
              style="cursor: pointer;"
              @click="selectTemplate(template)"
            >
              <p style="margin: 0 auto 0 0;">{{ template.name }}</p>
              <button
                class="button is-info tooltip is-tooltip-bottom is-small mr-2"
                data-tooltip="Edit"
                @click="editTemplate($event, template)"
              >
                <span class="icon">
                  <icon :icon="['fas', 'edit']" />
                </span>
              </button>
              <button
                class="button is-danger tooltip is-tooltip-bottom is-small"
                :class="{'is-loading': deleting}"
                data-tooltip="Delete"
                @click="deleteTempalte($event, template)"
              >
                <span class="icon">
                  <icon :icon="['fas', 'trash-alt']" />
                </span>
              </button>
            </div>
          </template>
        </div>
      </article>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button" :disabled="exporting" @click="onCancel">Cancel</button>
      <button v-if="creating" class="button is-success" :class="{ 'is-loading': savingTemplate }" @click="saveTemplate">Save changes</button>
      <div
          v-if="!creating"
          class="dropdown is-right is-up"
          :class="{'is-active': dropdown}"
          @click="showDropDown"
        >
          <div class="dropdown-trigger">
            <button :disabled="!selectedTemplate" class="button is-info" :class="{'is-loading': exporting}" aria-haspopup="true" aria-controls="dropdown-menu">
              <span>Export to</span>
              <span class="icon is-small">
                <icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="moodal-dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a href="#" class="dropdown-item" @click="exportToCsv">
                <span class="icon">
                  <icon :icon="['fas', 'file-csv']" />
                </span>
                <span>CSV</span>
              </a>
              <a href="#" class="dropdown-item" @click="exportPdf">
                <span class="icon">
                  <icon :icon="['fas', 'file-pdf']" />
                </span>
                <span>PDF</span>
              </a>
            </div>
          </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import ExportMixin from '@/components/mixins/ExportMixin';
const documentListener = function () {
  if (this.dropdown) {
    this.dropdown = false;
  }
};
export default {
  name: 'TemplateList',
  mixins: [ExportMixin],
  props: {
    formData: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      templateName: null,
      templates: [],
      creating: false,
      viewer: false,
      fields: [],
      savingTemplate: false,
      selectedTemplate: null,
      dropdown: false,
      editing: null,
      deleting: false,
      linkedForms: [],
    };
  },
  async beforeMount() {
    const id = this.formData._id;
    const response = await axios.get($formsConfig.core.api.templates(id));
    this.templates = response.data;
    await this.resetFields();
    document.addEventListener('click', documentListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('click', documentListener.bind(this));
  },
  methods: {
    showDropDown(e) {
      if (!this.selectedTemplate || this.exporting) {
        return;
      }
      e.stopPropagation();
      this.dropdown = !this.dropdown;
    },
    onCancel(destroyModal) {
      if (destroyModal === true) {
        this.creating = false;
        this.templateName = null;
        this.viewer = false;
        this.selectedTemplate = null;
        this.$emit('onCancel');
        return;
      }
      if (this.creating) {
        this.creating = false;
        this.editing = null;
        this.templateName = null;
      } else if (this.viewer) {
        this.viewer = false;
        this.selectedTemplate = null;
      } else {
        this.$emit('onCancel');
      }
    },
    updateInput(idx, field) {
      if (!field.checked) {
        field.alias = null;
      } else {
        this.$nextTick(() => {
          this.$refs[idx + field.fieldId][0].focus();
        });
      }
    },
    async saveTemplate() {
      const fields = this.fields.filter(field => field.checked);
      const linkedForms = this.linkedForms.map(lf => {
        lf.fields = lf.fields.filter(field => field.checked);
        return lf;
      });
      const data = {
        name: this.templateName,
        formId: this.formData._id,
        linkedForms,
        fields,
      };
      try {
        this.savingTemplate = true;
        const target = this.editing ? `put` : `post`;
        const uri = $formsConfig.core.api.templates(this.editing|| ``);
        const response = await axios[target](uri, data);
        if (this.editing) {
          const idxUpdate = this.templates.findIndex(t => t._id === response.data._id);
          this.templates[idxUpdate] = response.data;
        } else {
          this.templates.push(response.data);
        }
        this.selectedTemplate = response.data
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text:
            (error && error.message) ||
            'We were unable to save template.',
          type: 'error'
        });
        console.error('Error while saving template: ', error);
      } finally {
        this.resetFields();
        this.savingTemplate = false;
        this.creating = false;
        this.editing = null;
        this.viewer = true;
      }
    },
    onCreateNew() {
      this.creating = true;
      this.$nextTick(() => {
        this.resetFields();
        this.$refs.templateName.focus();
      });
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
      this.viewer = true;
    },
    async editTemplate(evt, template) {
      evt.stopPropagation();
      await this.resetFields();
      // Set template config.
      template.fields.forEach(f => {
        for (const [i, ff] of this.fields.entries()) {
          if (ff.fieldId === f.fieldId) {
            this.fields[i] = f;
            break;
          }
        }
      });
      template.linkedForms.forEach(tlf => {
        for (const lf of this.linkedForms) {
          if (lf._id === tlf._id) {
            tlf.fields.forEach(f => {
              for (const [i, ff] of lf.fields.entries()) {
                if (ff.fieldId === f.fieldId) {
                  lf.fields[i] = f;
                  break;
                }
              }
            });
            break;
          }
        }
      });
      this.templateName = template.name;
      this.creating = true;
      this.editing = template._id;
    },
    async resetFields() {
      this.fields = [];
      this.linkedForms = [];
      const getFields = async fields => {
        const newFields = [];
        for (const field of fields) {
          if (field?.keyName === 'FormField') {
            const formResponse = await axios.get($formsConfig.core.api.forms + field.value);
            formResponse.data.fields.forEach(f => {
              newFields.push({
                fieldId: f.id,
                name: f.label,
                alias: null,
                checked: false,
              });
            });
            break;
          }
          newFields.push({
            fieldId: field.id,
            name: field.label,
            alias: null,
            checked: false,
          });
        }
        return newFields;
      };
      this.fields = await getFields(this.formData.fields);
      // If linked forms exist, then save their fields in reactive object.
      if (this.formData.linkedForms.length) {
        for (const linkedForm of this.formData.linkedForms) {
          const lf = { ...linkedForm };
          lf.fields = await getFields(linkedForm.fields);
          this.linkedForms.push(lf);
        }
      }
    },
    async deleteTempalte(evt, template) {
      evt.stopPropagation();
      this.deleting = true;
      try {
        const response = await axios.delete($formsConfig.core.api.templates(template._id));
        const index = this.templates.findIndex(t => t._id === template._id);
        this.templates.splice(index, 1);
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text:
            (error && error.message) ||
            'We were unable to delete template.',
          type: 'error'
        });
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .main-container {
    max-height: 50vh;
    min-height: 15vh;
    overflow: auto;
    padding: .75rem;
  }
</style>
